<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :layout="layout"
      :label-align="labelAlign"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row :gutter="gutter">
        <a-col :span="24">
          <div class="common-title">课程基本信息</div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="课程名称" prop="name">
            <a-input v-model="form.name" placeholder="输入课程名称（最多30字）" :maxLength="30" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="课程类型" prop="train_id">
            <a-select
              placeholder="选择课程类型"
              :options="typeOptions"
              v-model="form.train_id"
              allowClear
              show-search
              option-filter-prop="children"
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="授课形式" prop="form">
            <a-select placeholder="选择授课形式" :options="teachingFormatOptions" v-model="form.form" allowClear>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item class="mb-0">
            <template v-slot:label>
              <span class="ant-form-item-required">是否有考试</span>
            </template>
            <a-form-model-item label="" prop="is_exam" class="input-item">
              <a-radio-group v-model="form.is_exam">
                <a-radio :value="item.value" v-for="item in flagOptions" :key="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
              <!-- <span style="margin-left: 10px; color: red;" v-show="form.form == 1 && form.is_exam == 1 && !form.question_bank_id">请选择考题</span> -->
            </a-form-model-item>
            <a-form-model-item
              label=""
              prop="question_bank_id"
              class="input-item"
              v-if="form.form == 1 && form.is_exam == 1"
            >
              <a-button type="primary" @click="selectQuetion">选择考题</a-button>
            </a-form-model-item>
          </a-form-model-item>
        </a-col>
        <!-- 线上 -->
        <template v-if="form.form == 1 && form.is_exam == 1">
          <a-col :span="12">
            <a-form-model-item class="mb-0">
              <template v-slot:label>
                <span class="ant-form-item-required">是否设置考试入口开放时间</span>
              </template>
              <a-form-model-item label="" prop="is_set_time" class="input-item">
                <a-radio-group v-model="form.is_set_time">
                  <a-radio :value="item.value" v-for="item in flagOptions" :key="item.value">
                    {{ item.label }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="" prop="opening_hours" class="input-item" v-if="form.is_set_time == 1">
                <a-date-picker
                  v-model="form.opening_hours"
                  :show-time="{ format: timeFormat }"
                  :format="format"
                  :value-format="format"
                  placeholder="选择时间"
                  allowClear
                />
              </a-form-model-item>
            </a-form-model-item>
          </a-col>
        </template>
        <!-- 线下 -->
        <template v-if="form.form == 2">
          <a-col :span="12">
            <a-form-model-item class="mb-0">
              <template v-slot:label>
                <label title="培训地点" class="ant-form-item-required">培训地点</label>
              </template>
              <a-row :gutter="gutter / 3">
                <a-col :span="9">
                  <a-form-model-item label="" prop="train_place" class="special-item">
                    <a-cascader :options="city" placeholder="请选择所在地区" v-model="form.train_place" allowClear />
                  </a-form-model-item>
                </a-col>
                <a-col :span="15">
                  <a-form-model-item label="" prop="train_address" class="special-item">
                    <a-input v-model="form.train_address" placeholder="输入详细地址" allowClear></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="可打卡时间" prop="clock_in_time">
              <a-range-picker
                v-model="form.clock_in_time"
                :show-time="{ format: timeFormat }"
                :format="format"
                :value-format="format"
                :placeholder="['开始时间', '结束时间']"
                allowClear
                @change="timeChange"
              />
            </a-form-model-item>
          </a-col>
          <template v-if="form.is_exam == 1">
            <a-col :span="12">
              <a-form-model-item label="考试地点" required validateStatus="success" class="mb-0">
                <a-row :gutter="gutter / 3">
                  <a-col :span="9">
                    <a-form-model-item label="" prop="exam_place" class="special-item">
                      <a-cascader :options="city" placeholder="请选择所在地区" v-model="form.exam_place" allowClear />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="15">
                    <a-form-model-item label="" prop="exam_address" class="special-item">
                      <a-input v-model="form.exam_address" placeholder="输入详细地址" allowClear></a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="考试时间" prop="exam_time">
                <a-date-picker
                  v-model="form.exam_time"
                  :show-time="{ format: timeFormat }"
                  :format="format"
                  :value-format="format"
                  placeholder="选择时间"
                  allowClear
                />
              </a-form-model-item>
            </a-col>
          </template>
        </template>
        <a-col :span="12">
          <a-form-model-item label="课程编号" prop="course_num">
            <a-input v-model="form.course_num" placeholder="输入课程编号" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <!-- 线上 -->
        <template v-if="form.form == 1">
          <a-col :span="24">
            <a-form-model-item
              label="课件内容"
              prop="courseware_type"
              :label-col="halfLabelCol"
              :wrapper-col="halfWrapperCol"
            >
              <a-radio-group v-model="form.courseware_type">
                <a-radio :value="item.value" v-for="item in coursewareOptions" :key="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" v-if="form.courseware_type == 1">
            <a-form-model-item label="直播链接" prop="link" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
              <a-input v-model="form.link" placeholder="输入直播链接" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" v-if="form.courseware_type == 2">
            <a-form-model-item label="上传视频" prop="video" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
              <form-upload v-model="form.video" mediaType="video" :size="500 * 1024 * 1024"></form-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" v-if="form.courseware_type == 3">
            <a-form-model-item
              label="图文内容"
              prop="image_text"
              :label-col="halfLabelCol"
              :wrapper-col="halfWrapperCol"
            >
              <rich-editor v-model="form.image_text" placeholder="输入图文内容"></rich-editor>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" v-if="form.courseware_type == 4">
            <a-form-model-item label="上传PDF文件" prop="pdf" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
              <form-upload
                v-model="form.pdf"
                listType="text"
                customAccept=".pdf"
                :size="500 * 1024 * 1024"
              ></form-upload>
            </a-form-model-item>
          </a-col>
        </template>
        <!-- 线下 -->
        <template v-else-if="form.form == 2">
          <a-col :span="12">
            <a-form-model-item class="mb-0">
              <template v-slot:label>
                <span class="ant-form-item-required">名额限制</span>
              </template>
              <a-form-model-item label="" prop="is_limit" class="input-item">
                <a-radio-group v-model="form.is_limit">
                  <a-radio :value="item.value" v-for="item in numberOptions" :key="item.value">
                    {{ item.label }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="" prop="limit_people" class="input-item" v-if="form.is_limit == 2">
                <a-input-number
                  v-model="form.limit_people"
                  placeholder="请输入数字"
                  :min="1"
                  :step="1"
                  allowClear
                ></a-input-number>
              </a-form-model-item>
            </a-form-model-item>
          </a-col>
        </template>
        <a-col :span="24">
          <a-form-model-item label="课程介绍" prop="course" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.course" placeholder="输入课程介绍"></rich-editor>
          </a-form-model-item>
        </a-col>
        <!-- 线下 -->
        <template v-if="form.form == 2">
          <a-col :span="24">
            <div class="common-title">核销人管理</div>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
              <VerifierManage v-model="form.userRole" :privilege="5"></VerifierManage>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <div class="common-title">报名限制条件</div>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="可确认志工报名名单的人（多选）" prop="confirm_role">
              <a-select
                placeholder="请选择"
                mode="multiple"
                :options="catOptions"
                v-model="form.confirm_role"
                allowClear
              >
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="可进行报名的志工状态（多选）">
              <a-select
                placeholder="请选择"
                mode="multiple"
                :options="courseOptions"
                v-model="form.volunteer_status"
                allowClear
              >
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <upload-volunteer v-model="form.volunteer_id" label="仅以下志工可报名"></upload-volunteer>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="可进行报名的年龄范围">
              <a-space>
                <a-input-number
                  v-model="form.min_age"
                  placeholder="请输入最小年龄（岁）"
                  :min="1"
                  :step="1"
                  allowClear
                ></a-input-number>
                <span>~</span>
                <a-input-number
                  v-model="form.max_age"
                  placeholder="请输入最大年龄（岁）"
                  :min="1"
                  :step="1"
                  allowClear
                ></a-input-number>
              </a-space>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
              <template v-slot:label>
                <a-space size="large">
                  <span>仅已选中的小组可参与</span>
                  <a class="txt-btn" @click="addGroup">添加小组</a>
                </a-space>
              </template>
              <div v-for="(item, index) in form.joinGroup" :key="item.key">
                <a-form-model-item label="" class="select-item mb-0">
                  <a-select
                    placeholder="请选择小组（支持搜索）"
                    :options="groupOptions"
                    v-model="item.volunteer_group_id"
                    allowClear
                    show-search
                    option-filter-prop="children"
                  >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  label="最多报名人数："
                  :label-col="{ span: 8 }"
                  :wrapper-col="{ span: 16 }"
                  class="select-item limit-item mb-0"
                >
                  <a-input-number
                    v-model="item.enrollment"
                    placeholder="请输入数字"
                    :min="1"
                    :step="1"
                    allowClear
                  ></a-input-number>
                </a-form-model-item>
                <span class="txt-btn danger del-btn" @click="delGroup(index)">删除</span>
              </div>
            </a-form-model-item>
          </a-col>
        </template>
      </a-row>
    </a-form-model>
    <QuestionModal v-if="visible" v-model="visible" :form="form" @confirm="confirm"></QuestionModal>
  </div>
</template>

<script>
import {
  clone,
  dataConvertOptions,
  isEmpty,
  joinListKey,
  randomString,
  stringConvertTimestamp,
  timestampConvertString,
} from "../../../../common/js/tool";
import {
  teachingFormatOptions,
  flagOptions,
  coursewareOptions,
  numberOptions,
  catOptions,
  volunStatusOptions,
} from "../../../../common/hr/volunteer";
import QuestionModal from "./QuestionModal.vue";
import VerifierManage from "./VerifierManage.vue";
import city from "../../../../common/constant/city";

const coursewareTypeMap = {
  1: "link",
  2: "video",
  3: "image_text",
  4: "pdf",
};

const joinKeys = ["train_place", "exam_place", "confirm_role", "volunteer_status"];

export default {
  name: "TrainForm",
  components: {
    QuestionModal,
    VerifierManage,
  },
  props: {
    initial: Object,
    halfLabelCol: Object,
    halfWrapperCol: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelAlign: {
      type: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    gutter: {
      type: [Number, String, Array],
      default: 32,
    },
  },
  data() {
    return {
      format: "YYYY-MM-DD HH:mm",
      timeFormat: "HH:mm",
      form: {},
      rules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        train_id: [{ required: true, message: "请选择课程类型", trigger: "change" }],
        form: [{ required: true, message: "请选择授课形式", trigger: "change" }],
        is_exam: [{ required: true, message: "请选择是否有考试", trigger: "change" }],
        question_bank_id: [{ required: true, message: "请设置考题", trigger: "change" }],
        is_set_time: [{ required: true, message: "请选择是否设置考试入口开放时间", trigger: "change" }],
        opening_hours: [{ required: true, message: "请选择开放时间", trigger: "change" }],
        train_place: [{ required: true, message: "请选择所在地区", trigger: "change" }],
        train_address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        clock_in_time: [{ required: true, message: "请选择可打卡时间", trigger: "change" }],
        exam_place: [{ required: true, message: "请选择所在地区", trigger: "change" }],
        exam_address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        exam_time: [{ required: true, message: "请选择考试时间", trigger: "change" }],
        course_num: [{ required: true, message: "请输入课程编号", trigger: "blur" }],
        is_limit: [{ required: true, message: "请选择是否限制名额", trigger: "change" }],
        limit_people: [{ required: true, message: "请输入限制名额", trigger: "blur" }],
        courseware_type: [{ required: true, message: "请选择课件内容类型", trigger: "change" }],
        link: [{ required: true, message: "请输入直播链接", trigger: "blur" }],
        video: [{ required: true, message: "请上传视频", trigger: "change" }],
        image_text: [{ required: true, message: "请上传视频", trigger: "blur" }],
        pdf: [{ required: true, message: "请上传PDF文件", trigger: "change" }],
        course: [{ required: true, message: "请输入课程介绍", trigger: "blur" }],
        confirm_role: [{ required: true, message: "请选择可确认志工报名名单的人", trigger: "change" }],
      },
      typeOptions: [],
      teachingFormatOptions,
      flagOptions,
      coursewareOptions,
      numberOptions,
      catOptions,
      volunStatusOptions,
      groupOptions: [],
      visible: false,
      city,
      courseOptions: [],
      typePromise: null,
    };
  },
  watch: {
    initial: {
      handler() {
        this.init();
      },
      immediate: true,
    },
    "form.train_id"() {
      this.trainChange();
    },
    "form.form"(val) {
      // 切换线下课程，默认创建一个限制小组
      if (val == 2 && this.form.joinGroup?.length == 0) {
        this.$set(this.form, "joinGroup", [{ key: randomString(16) }]);
      }
    },
    "form.courseware_type"(val, oldVal) {
      if (oldVal) {
        // 切换类型，移除原先的校验
        this.$refs.ruleForm.clearValidate(coursewareTypeMap[oldVal]);
      }
    },
  },
  created() {
    this.typePromise = this.getType();
    this.getGroup();
    // this.init();
  },
  methods: {
    getType() {
      return this.$axios({
        url: "/admin/volunteer-train-type",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
          expand: "volunteerStatus",
        },
      }).then((res) => {
        this.typeOptions = dataConvertOptions(res.data);
        // 设置可进行报名的志工状态列表
        // this.trainChange();
      });
    },
    init() {
      let initial = this.initial;
      if (initial) {
        let form = clone(initial);
        // 开放时间
        if (form.opening_hours) {
          form.opening_hours = timestampConvertString(form.opening_hours);
        }
        // 打卡时间
        if (form.clock_in_start_time && form.clock_in_end_time) {
          form.clock_in_time = [
            timestampConvertString(form.clock_in_start_time),
            timestampConvertString(form.clock_in_end_time),
          ];
        }
        // 考试时间
        if (form.exam_time) {
          form.exam_time = timestampConvertString(form.exam_time);
        }
        // 课件内容
        if (form.courseware_type) {
          let key = coursewareTypeMap[form.courseware_type];
          form[key] = form.courseware;
        }
        if (form.confirm_role) {
          form.confirm_role = form.confirm_role.split(",").map(Number);
        } else {
          form.confirm_role = [];
        }
        if (form.volunteer_status) {
          form.volunteer_status = form.volunteer_status.split(",").map(Number);
        } else {
          form.volunteer_status = [];
        }
        if (form.train_place) {
          form.train_place = form.train_place.split(",");
        }
        if (form.exam_place) {
          form.exam_place = form.exam_place.split(",");
        }
        this.form = form;
      }
    },
    // 获取小组列表
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    timeChange(value, dateString) {
      // console.log("timeChange:", value, dateString);
      if (dateString.length > 0) {
        this.$set(this.form, "clock_in_start_time", stringConvertTimestamp(dateString[0]));
        this.$set(this.form, "clock_in_end_time", stringConvertTimestamp(dateString[1]));
      } else {
        this.$set(this.form, "clock_in_start_time", undefined);
        this.$set(this.form, "clock_in_end_time", undefined);
      }
    },
    selectQuetion() {
      this.visible = true;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((flag) => {
          if (flag) {
            let form = clone(this.form);
            if (form.opening_hours) {
              form.opening_hours = stringConvertTimestamp(form.opening_hours);
            }
            if (form.exam_time) {
              form.exam_time = stringConvertTimestamp(form.exam_time);
            }
            joinKeys.forEach((key) => {
              if (!isEmpty(form[key])) {
                form[key] = form[key].join(",");
              }
            });
            // 课件内容
            if (form.courseware_type) {
              let key = coursewareTypeMap[form.courseware_type];
              form.courseware = form[key];
            }
            // 核销人
            if (form.userRole) {
              form.user_role_id = joinListKey(form.userRole);
            }
            // 仅已选中的小组可参与
            if (form.joinGroup) {
              let oldGroup = clone(this.initial?.joinGroup || []);
              let join_group = {
                add: [],
                update: [],
                delete: [],
              };
              form.joinGroup = form.joinGroup?.filter(
                (item) => !isEmpty(item.id) || (!isEmpty(item.volunteer_group_id) && !isEmpty(item.enrollment))
              ); // 筛选 存在id 和 名称与报名人数填写完整的
              form.joinGroup?.forEach((item) => {
                if (item.id) {
                  join_group.update.push(item);
                  // 去除当前仍存在的，剩下的为删除的
                  let index = oldGroup.findIndex((old) => old.id == item.id);
                  oldGroup.splice(index, 1);
                } else if (item.key) {
                  join_group.add.push(item);
                }
              });
              join_group.delete = oldGroup;
              form.join_group = JSON.stringify(join_group);
            }
            resolve(form);
          } else {
            reject();
          }
        });
      });
    },
    confirm(formData) {
      this.form = formData;
      this.visible = false;
    },
    // 添加小组
    addGroup() {
      let item = {
        key: randomString(16),
      };
      let joinGroup = this.form.joinGroup;
      if (joinGroup) {
        joinGroup.push(item);
      } else {
        joinGroup = [item];
      }
      this.$set(this.form, "joinGroup", joinGroup);
    },
    // 删除小组
    delGroup(index) {
      this.form.joinGroup.splice(index, 1);
    },
    trainChange() {
      let train_id = this.form.train_id;
      if (train_id) {
        this.typePromise.then(() => {
          let target = this.typeOptions.find((item) => item.value == train_id);
          let volunteer_status = target.volunteer_status;
          let options = [];
          volunteer_status?.split(",").forEach((status) => {
            let option = volunStatusOptions.find((item) => item.value == status);
            options.push(option);
          });
          this.courseOptions = options;
        });
      } else {
        this.courseOptions = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  min-height: 40px;
  line-height: 32px;
}
.input-item {
  display: inline-block;
}
.special-item {
  display: inline-block;
  width: 100%;
  padding: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ant-input-number {
  width: 240px;
}
.common-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
.select-item {
  display: inline-block;
  ::v-deep &.limit-item {
    margin-left: 32px;
    .ant-form-item-label {
      line-height: 32px;
    }
  }
  .ant-select {
    width: 240px;
  }
}
.del-btn {
  margin-left: 64px;
  line-height: 32px;
}
</style>
