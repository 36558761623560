<template>
  <a-modal
    v-model="visible"
    :width="1000"
    :maskClosable="false"
    title="选择考题"
    ok-text="确认"
    cancel-text="取消"
    @cancel="cancel"
    @ok="confirm"
  >
    <a-form-model ref="ruleForm" :model="formData" :rules="rules" layout="vertical" label-align="left">
      <a-form-model-item label="考题" prop="question_bank_id">
        <a-transfer
          :titles="['全部题目', '已选题目']"
          :data-source="dataList"
          :target-keys="formData.question_bank_id"
          :disabled="disabled"
          :show-search="showSearch"
          :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
          :show-select-all="false"
          @change="onChange"
        >
          <template
            slot="children"
            slot-scope="{
              props: { filteredItems, selectedKeys, disabled: listDisabled },
              on: { itemSelectAll, itemSelect },
            }"
          >
            <a-table
              :row-selection="getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
              :columns="commonColumns"
              :data-source="filteredItems"
              size="small"
              :style="{ pointerEvents: listDisabled ? 'none' : null }"
              :custom-row="
                ({ key, disabled: itemDisabled }) => ({
                  on: {
                    click: () => {
                      if (itemDisabled || listDisabled) return;
                      itemSelect(key, !selectedKeys.includes(key));
                    },
                  },
                })
              "
            />
          </template>
        </a-transfer>
      </a-form-model-item>
      <a-form-model-item label="考试总分" prop="total_score">
        <a-input-number v-model="formData.total_score" placeholder="考试总分" allowClear disabled></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="成绩及格线" prop="num">
        <a-space>
          <a-input-number v-model="formData.total_score" placeholder="考试总分" allowClear disabled></a-input-number>
          <span>X</span>
          <a-input-number
            v-model="formData.num"
            placeholder="请输入1-100之间的数字（%）"
            :min="1"
            :max="100"
            :step="1"
            allowClear
          ></a-input-number>
          <span>=</span>
          <a-input-number v-model="passing_mark" placeholder="及格线分数" allowClear disabled></a-input-number>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { clone, isEmpty } from "../../../../common/js/tool";
const difference = function(arr, ...rest) {
  // 扁平化rest
  const target = rest.reduce((pre, cur) => {
    return pre.concat(cur);
  }, []);
  return arr.filter((item) => !target.includes(item));
};

const dataList = [];
for (let i = 1; i < 20; i++) {
  dataList.push({
    key: i.toString(),
    title: `content${i}`,
    score: Math.floor(Math.random() * 100),
  });
}

// const originTargetKeys = dataList.filter((item) => +item.key % 3 > 1).map((item) => item.key);

export default {
  name: "QuestionModal",
  model: {
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    form: Object,
  },
  data() {
    return {
      formData: {},
      rules: {
        question_bank_id: [{ required: true, message: "请选择题目", trigger: "change", type: "array" }],
        total_score: [{ required: true, message: "请输入考试总分", trigger: "blur" }],
        num: [{ required: true, message: "请输入比例", trigger: "blur" }],
      },

      dataList,
      disabled: false,
      showSearch: true,
      commonColumns: [
        {
          dataIndex: "title",
          title: "题目",
        },
        {
          dataIndex: "score",
          title: "分值",
        },
      ],
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    passing_mark() {
      let formData = this.formData;
      let total_score = formData.total_score;
      let num = formData.num;
      let mark = null;
      if (!isEmpty(total_score) && !isEmpty(num)) {
        mark = Math.round(total_score * (num / 100));
      }
      return mark;
    },
  },
  // watch: {
  //   form() {
  //     this.setForm();
  //   },
  // },
  created() {
    this.setForm();
    this.getList();
  },
  methods: {
    getList() {
      let params = { sort: "-id", pageSize: 9999 };
      let train_id = this.formData.train_id;
      if (train_id) {
        params["filter[train_id]"] = train_id;
      }
      this.$axios({ url: "/admin/volunteer-question-bank", methods: "GET", params }).then((res) => {
        this.dataList = res.data?.map((item) => {
          return {
            key: item.id.toString(),
            title: item.name,
            ...item,
          };
        });
      });
    },
    setForm() {
      let form = this.form;
      this.formData = clone(form);
      if (this.formData.question_bank_id) {
        this.formData.question_bank_id = this.formData.question_bank_id.split(",");
      } else {
        this.formData.question_bank_id = [];
      }
    },
    cancel() {
      this.formData = {};
    },
    confirm() {
      this.$refs.ruleForm.validate((flag) => {
        if (flag) {
          let form = clone(this.formData);
          if (form.question_bank_id) {
            form.question_bank_id = form.question_bank_id.join(",");
          }
          form.pass_mark = this.passing_mark;
          this.$emit("confirm", form);
        }
      });
    },
    onChange(nextTargetKeys) {
      this.$set(this.formData, "question_bank_id", nextTargetKeys);
      let total_score = 0;
      nextTargetKeys.forEach((key) => {
        let target = this.dataList.find((item) => item.key == key);
        total_score += target.score;
      });
      this.$set(this.formData, "total_score", total_score);
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.ant-input-number {
  width: 240px;
}
</style>
